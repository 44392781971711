var DS = window.DS || {};
DS.tabs = function() {
    var els = {};
    var vars = {};

    var buildTabContent = function() {
        els.slides.find('img').on('load', function(){
          els.slides.slick('resize');
        });
        els.slides.slick({
            arrows: false,
            infinite: false,
            fade: true,
            dots: false,
            slidesToShow: 1,
            waitForAnimate: false,
            touchMove: false,
            swipe: false,
            accessibility: false
        });
    };

    var buildTabNavigationControls = function() {
        els.labels.off().on({
            click: function() {
                setActiveTab($(this));
            },
            keydown: function(e) {
                if(e.keyCode === 13) {
                    setActiveTab($(this));
                }
            }
        });
    };

    var setActiveTab = function($tab) {
        var isStackedComponenet = $tab.parents('.stacked');
        var activeSlide = els.slides.find('.slick-active');

        if(!$tab.hasClass('active')) {
            var index = $tab.attr('data-tab-index');
            els.labels.removeClass('active');
            els.slides.slick('slickGoTo',index);

            // Remove tabindex from all play buttons & links (ADA)
            els.slides.find('.link').attr('tabindex', -1);
            els.playButton.attr('tabindex', -1);

            activeSlide = els.slides.find('.slick-active');

            if (isStackedComponenet.length) {
                // Remove all tabindex (ADA)
                els.slides.find('.tab-content-panel *').attr('tabindex', -1);
                els.slides.find('.tab-labels *').attr('tabindex', -1);

                // Set tabindex for active slide (ADA)
                activeSlide.find('.tab-content-panel *').attr('tabindex', 0);

                activeSlide.find('.tab-label').each(function(){
                    var $this = $(this);
                    var currentTab = $this.attr('data-tab-index') === index;
                    if ( currentTab ) {
                        $this.addClass('active').attr('tabindex', 0).focus();
                        $this.siblings().attr('tabindex', 0);
                    }
                });
            }
            else {
              $tab.addClass('active');
              setFocus(activeSlide);
            }
          }
          else {
            setFocus(activeSlide);
          }
    };

    // Set focus to play-button/link in tab (ADA)
    var setFocus = function(activeSlide) {
      activeSlide.find('.vjs-big-play-button').attr('tabindex', 0);
      activeSlide.find('.link').attr('tabindex', 0);

      if ( activeSlide.find('.vjs-big-play-button').length !== 0 ) {
        vars.activeTabLink = setTimeout(function() {
          activeSlide.find('.vjs-big-play-button').focus();
        },100);
      }
      else {
        vars.activeTabLink = setTimeout(function() {
            activeSlide.find('.tab-content-panel').children(".link").focus();
        },100);
      }
    };

    var destroyTabCarousel = function() {
        els.slides.slick('unslick');
    };

    var openPanels = function() {
        els.labelMobile.off().on('click', function() {
            var speed = 500;
            var $mobilePanelContainer = $(this).parents('.tab-content-panels-container-mobile');
            if($mobilePanelContainer.hasClass('open')) {
                $mobilePanelContainer.removeClass('open').find('.wrapper').slideUp(speed);
            } else {
                els.mobilePanelContainers.removeClass('open').find('.wrapper').slideUp(speed);
                $mobilePanelContainer.addClass('open').find('.wrapper').slideDown(speed, function () {
                    $('html, body').animate({
                        scrollTop: $(this).parents('.tab-content-panels-container-mobile').offset().top
                    }, speed);
                });
            }
        });
    };

    var resizeTabs = function() {
        window.addEventListener('resize', function () {
            if(window.innerWidth < 769 && !els.component.hasClass('no-panels-mobile')) {
                if(els.slides.hasClass('slick-initialized')) {
                    clearTimeout(vars.adjustTabsComponentMobile);
                    vars.adjustTabsComponentMobile = setTimeout(function() {
                        destroyTabCarousel();
                        openPanels();
                    },100);
                }
            } else {
                if(!els.slides.hasClass('slick-initialized')) {
                    clearTimeout(vars.adjustTabsComponentDesktop);
                    vars.adjustTabsComponentDesktop = setTimeout(function() {
                        buildTabContent();
                        buildTabNavigationControls();
                        els.mobilePanelContainers.removeClass('open');
                    },100);
                }
            }
        });
    };

    return {
        init: function(el) {
            var $el = $(el);
            els = {
                component: $el,
                slides : $el.find('.tab-slide-wrapper'),
                slide : $el.find('.tab-slide'),
                labels : $el.find('.tab-label'),
                labelMobile : $el.find('.tab-label-mobile'),
                mobilePanelContainers: $el.find('.tab-content-panels-container-mobile'),
                playButton: $el.find('.vjs-big-play-button')
            };
            vars = {
                adjustTabsComponentMobile: null,
                adjustTabsComponentDesktop: null
            };
            if (window.innerWidth > 768 || els.component.hasClass('no-panels-mobile')) {
              buildTabContent();
              buildTabNavigationControls();
            } else {
                openPanels();
            }
            resizeTabs();
        }
    };
};

$(function() {
    $('.tabs-component').each(function(i,el) {
        var tabs = new DS.tabs();
        tabs.init(el);
    });
});
