var DS = window.DS || {};
DS.filterableDrinks = function(){
  var els = {};
  var vars = {};
  var data;

  var listen = function(){
    els.regionSelect.on('change', regionSelectChange);
    els.countrySelect.on('change', countrySelectChange);
    els.stateSelect.on('change', stateSelectChange);
    els.citySelect.on('change', citySelectChange);
  };

  var regionSelectChange = function(){
    var val = $(this).val();
    vars.selectedRegion = val == 'default' ? null : val;
    vars.selectedCountry = null;
    vars.selectedState = null;
    vars.selectedCity = null;
    vars.selectedProperty = null;

    filterDrinks();
    resetSelects();

    if (vars.selectedRegion) {
      buildSelect({
        selectType: 'country',
        selectObject: data[vars.selectedRegion]['countries'],
        selectText: vars.componentData.langSelectCountry,
        selectEl: els.countrySelect
      });
    }

    return false;
  }

  var countrySelectChange = function(){
    var val = $(this).val();
    vars.selectedCountry = val == 'default' ? null : val;
    vars.selectedState = null;
    vars.selectedCity = null;
    vars.selectedProperty = null;

    filterDrinks();
    resetSelects();

    if (hasStates(data[vars.selectedRegion]['countries'][vars.selectedCountry])){
      els.stateSelect.closest('.dropdown-component').show();
      els.citySelect.closest('.dropdown-component').hide();
      if (vars.selectedCountry) {
        buildSelect({
          selectType: 'state',
          selectObject: data[vars.selectedRegion]['countries'][vars.selectedCountry]['states'],
          selectText: vars.componentData.langSelectState,
          selectEl: els.stateSelect
        });
      }
    } else {
      els.stateSelect.closest('.dropdown-component').hide();
      els.citySelect.closest('.dropdown-component').show();
      if (vars.selectedCountry) {
        buildSelect({
          selectType: 'state',
          selectObject: data[vars.selectedRegion]['countries'][vars.selectedCountry]['cities'],
          selectText: vars.componentData.langSelectCity,
          selectEl: els.citySelect
        });
      }
    }

    return false;
  }

  var stateSelectChange = function(){
    var val = $(this).val();
    vars.selectedState = val == 'default' ? null : val;
    vars.selectedCity = null;

    filterDrinks();
    resetSelects();


    return false;
  }

  var citySelectChange = function(){
    var val = $(this).val();
    vars.selectedCity = val == 'default' ? null : val;

    filterDrinks();
    resetSelects();

    return false;
  }

  var filterDrinks = function(){

      if (vars.selectedCity) {
        els.drink.hide().filter('[data-region-code="'+vars.selectedRegion+'"]').show();
      } else if (vars.selectedState) {
        els.drink.hide().filter('[data-state-code="'+vars.selectedState+'"]').show();
      } else if (vars.selectedCountry){
        els.drink.hide().filter('[data-country-code="'+vars.selectedCountry+'"]').show();
      } else if (vars.selectedRegion){
        els.drink.hide().filter('[data-region-code="'+vars.selectedRegion+'"]').show();
      } else {
        els.drink.show();
      }

    return false;
  }

  var buildSelect = function(selectData){
    var type = selectData.selectType,
      data = selectData.selectObject,
      selectHtml = '<option value="default">'+selectData.selectText+'</option>';

    for (var key in data){
      if (key && data.hasOwnProperty(key)){
        selectHtml += '<option value="'+key+'">'+data[key]['name']+'</option>';
      }
    }

    selectData.selectEl.empty().append(selectHtml);
  }

  var resetSelects = function(){
    if (vars.selectedRegion == null){
      els.countrySelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
    } else {
      els.countrySelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
    };

    if (vars.selectedCountry == null){
      els.countrySelect.empty().append('<option value="default">'+vars.componentData.langSelectCountry+'</option>');
      els.stateSelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
      els.citySelect.prop('disabled', true).closest('.dropdown-component').addClass('disabled');
    } else {
      els.stateSelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
      els.citySelect.prop('disabled', false).closest('.dropdown-component').removeClass('disabled');
    };

    if (vars.selectedState == null){
      els.stateSelect.empty().append('<option value="default">'+vars.componentData.langSelectState+'</option>');
    };

    if (vars.selectedCity == null){
      els.citySelect.empty().append('<option value="default">'+vars.componentData.langSelectCity+'</option>');
    };

    return false;
  };

  var hasStates = function(countryObject){
    var count = 0;
    for (var key in countryObject){
      if (countryObject.hasOwnProperty(key)){
        count++;
      }
    }

    if (count > 0){
      return true;
    } else{
      return false;
    }
  };

  return {
    init: function(el){
      var $el = $(el);
      els = {
        component: $el,
        html: $('html'),
        body: $('body'),
        contentContainer: $el.find('.filterable-drinks-content'),
        regionSelect: $el.find('.filterable-drinks-region-select'),
        countrySelect: $el.find('.filterable-drinks-country-select'),
        stateSelect: $el.find('.filterable-drinks-state-select'),
        citySelect: $el.find('.filterable-drinks-city-select'),
        select: $el.find('.select-container select'),
        drink: $el.find('.drink')
      };

      vars = {
        locale: els.body.attr('data-locale'),
        lang: lang = $('html')[0].lang,
        componentData: els.component.data(),
        isMobile: window.app.isMobile(),
        selectedRegion: null,
        selectedCountry: null,
        selectedState: null,
        selectedCity: null
      };

      data = window.MARRIOTT_DRINKS_GEO_DATA;

      listen();
      buildSelect({
        selectType: 'region',
        selectObject: data,
        selectText: vars.componentData.langSelectRegion,
        selectEl: els.regionSelect
      });
    }
  };
};

$(function(){
  if ( $('.filterable-drinks-component').length > 0 ){
    $('.filterable-drinks-component').each(function(i,el){
      var filterableDrinks = new DS.filterableDrinks();
      filterableDrinks.init(el);
    });
  }
});
