var DS = window.DS || {};
DS.header = function() {
  var els = {};
  var focusoutTimout = null;

  var listen = function() {
    els.window.on({
      scroll: function(e) {
        if ( els.headerComponent.css("position") === "fixed" ) {
          els.headerComponent.toggleClass('scrolled', $(this).scrollTop() > els.headerComponent.height());
        }
      }
    });
    els.mobileNavButton.on({
      click: function (e) {
        els.mobileNavButton.toggleClass('is-active');
        els.headerComponent.toggleClass('mobile-nav-open');
        els.html.toggleClass('no-scroll');
      }
    });
    els.parentMenu.on({
      click: function (e) {
        if (window.app.isMobile()) {
          var $target = $(e.currentTarget);
          if ($target.hasClass('expanded')) {
            $target.removeClass('expanded');
            els.menuItems.removeClass('hidden');
          } else {
            els.menuItems.addClass('hidden');
            $target.removeClass('hidden');
            $target.addClass('expanded');
          }
        }
      },
      mouseenter: function (e) {
        if (!window.app.isMobile()) {
          var $target = $(e.currentTarget);
          els.headerComponent.addClass('open');
          $target.find('ul').show();
        }
      },
      mouseleave: function (e) {
        if (!window.app.isMobile()) {
          var $target = $(e.currentTarget);
          els.headerComponent.removeClass('open');
          $target.find('ul').hide();
        }
      },
      focusin: function (e) {
        clearTimeout(focusoutTimout);
        var $target = $(e.currentTarget);
        $target.addClass('expanded');
        $target.find('ul').show();
        if (!window.app.isMobile()) {
          els.headerComponent.addClass('open');
        }
      },
      focusout: function (e) {
        focusoutTimout = setTimeout(function() {
          var $target = $(e.currentTarget);
          $target.removeClass('expanded');
          $target.find('ul').hide();
          if (!window.app.isMobile()) {
            els.headerComponent.addClass('open');
          }
        }, 150);
      }
    });
    els.languageSelector.on({
      mouseenter: function (e) {
        if(!window.app.isMobile()){
          els.languageSelector.addClass('open');
          els.headerComponent.addClass('open');
        }
      },
      mouseleave: function (e) {
        if(!window.app.isMobile()){
          els.languageSelector.removeClass('open');
          els.headerComponent.removeClass('open');
        }
      }
    });
    els.languageSelector.find('.current-language').on({
      click: function (e) {
        if(window.app.isMobile()){
          els.languageSelector.addClass('open');
        }
      }
    });
    els.languageSelector.find('.language-close').on({
      click: function (e) {
        if(window.app.isMobile()){
          els.languageSelector.removeClass('open');
        }
      }
    });
    els.currentLanguage.on({
      focus: function() {
        if(!window.app.isMobile()) {
          els.languageSelector.addClass('open');
        }
      }
    });
    els.languages.last().on({
      blur: function() {
        setTimeout(function() {
          if(!window.app.isMobile()) {
            els.languageSelector.removeClass('open');
          }
        });
      }
    });
  };

  return {
    init: function() {
      els = {
        window: $(window),
        html: $('html'),
        menuItems: $('.nav-menu > li'),
        parentMenu: $('.menu-item-has-children'),
        parentMenuLinks: $('.menu-item-has-children a'),
        mobileNavButton: $('button.hamburger'),
        languageSelector: $('.language-selector'),
        headerComponent: $('.header-component'),
        currentLanguage: $('.header-component .current-language'),
        languages: $('.header-component .language-list li a')
      };
      listen();
    }
  };
};

$(function() {
  var header = new DS.header();
  header.init();
});
