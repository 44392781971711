var DS = window.DS || {};
DS.modalDrinks = function() {
    var els = {};
    var vars = {};

    var listen = function() {
        els.drinksLink.on({
            click: function(e){
                e.preventDefault();
                var $this = $(this);
                openModal($this);
            }
        });

        els.closeModal.on({
            click: function(e){
                closeModal();
            }
        });

        els.body.on({
            keyup: function(e) {
                if (e.keyCode === 27) closeModal();   // esc
            }
        });
    };

    var openModal = function($this) {
      if(!vars.isOpen) {
        vars.isOpen = true;
        var drinkID = $this.data('drinkId');
        var drinkData = vars.drinksData[drinkID];
        var modalHtml = vars.modalTemplate;
        modalHtml = modalHtml.replace('%property_name%', drinkData.property_name);
        modalHtml = modalHtml.replace('%name%', drinkData.name);
        modalHtml = modalHtml.replace('%description%', drinkData.description);
        modalHtml = modalHtml.replace('%recipe%', drinkData.recipe);
        modalHtml = modalHtml.replace('%alt%', drinkData.name);
        modalHtml = modalHtml.replace('<img src=""', '<img src="'+drinkData.image+'"');
        els.modalContent.empty().append(modalHtml);

        els.component.removeClass( 'close' );
        els.body.addClass( 'modal-open' );
        els.component.addClass( 'open' );

        // Set focus to close button for accessibility
        els.closeModal.focus();
      }
    };

    var closeModal = function() {
      if (vars.isOpen) {
        els.component.addClass( 'close' );
        els.body.removeClass( 'modal-open' );
        els.component.removeClass( 'open' );
        vars.isOpen = false;
      }
    };

    return {
        init: function(el) {
            var $el = $(el);

            els = {
                component: $el,
                drinksComponent: $( '.drinks-modal' ),
                modalContent: $el.find('.modal-content'),
                drinksLink: $( '.link[data-drink-id]' ),
                body: $( 'body' ),
                closeModal: $el.find( '.close-modal' ),
                modal: $el.find( '.modal' )
            };

            vars = {
                drinksData: window.MARRIOTT_DRINKS_DATA,
                isOpen: false,
                modalTemplate: $el.find('.modal-content').html()
            };

            listen();
        }
    };
};

$(function() {
    var $components = $( '.drinks-modal' );

    if ( $components.length > 0 ) {
        $components.each(function(i,el) {
            var modal = new DS.modalDrinks();
            modal.init(el);
        });
    }
});
