var DS = window.DS || {};
DS.modalVideo = function() {
    var els = {};
    var vars = {};

    var listen = function() {
        els.videoComponent.find('.video-js').on({
            click: function(e){
                e.preventDefault();
                var $this = $(this);
                els.curVideo = $this;

                openModal($this);
            },
            keydown: function(e) {
              // Opens video modal with enter or spacebar
              if (e.keyCode == 13 || e.keyCode == 32) {
                e.preventDefault();
                var $this = $(this);
                els.curVideo = $this;

                openModal($this);
              }
            }
        });

        els.closeModal.on({
            click: function(e){
                closeModal();
            }
        });

        els.body.on({
          keyup: function(e) {
              if (e.keyCode === 27) closeModal();   // esc
          },
          keydown: function(evt) {
            if (vars.isOpen) {
              var TABKEY = 9;
              $lastControl = $(els.body.find('.vjs-control:last-child'));

              if ( evt.keyCode === TABKEY && !evt.shiftKey) {
                if($lastControl.is(evt.target)){
                  evt.preventDefault();
                  els.closeModal.focus();
                }
              }
              else if (evt.keyCode === TABKEY  && evt.shiftKey) {
                if(els.closeModal.is(evt.target)){
                  evt.preventDefault();
                  $lastControl.focus();
                }
              }
            }
          },
        });
    };
    var trapFocus = function() {
      var lastButton = $('.vjs-control-bar').children().last()[0];
      var pressedKeys = {};

      onkeyup = onkeydown = function(e) {
        e = e || event; // to deal with IE
        pressedKeys[e.key] = e.type == 'keydown';

        if ( e.shiftKey && pressedKeys["Tab"] ) {
          if ( els.closeModal.is(':focus')) {
            $(lastButton).focus();
            pressedKeys = {};
            return false;
          }
        } else if ( pressedKeys["Tab"] ) {
          if ($(lastButton).is(':focus')) {
            els.closeModal.focus();
            pressedKeys = {};
            return false;
            }
          }
        }
    };

    var openModal = function($this, videoId, videoAccount, playerStyle, modalLink) {

        var elementId;
        vars.isOpen = true;
        els.modalLink = modalLink;
        els.modalComponent.removeClass( 'close' );
        els.body.addClass('modal-open');
        els.modalComponent.addClass('open');

        // Set focus to close button for accessibility
        els.closeModal.focus();

        var $modalVideo = els.modalComponent.find('video');

        if ($this) {
            var $video = $this;
            els.curPlayButton = $this.find('.vjs-big-play-button');
            elementId = $this.attr('id');
            // Get video ID
            vars.videoId = $video.data('video-id');
            // Get and append video account to modal video data attr
            vars.videoAccount = $video.data('account');
            // Get and append video player style to modal video data attr
            vars.videoPlayerStyle = $video.data('player');
        } else{
          els.curPlayButton = $('#video-modal').find('.vjs-big-play-button');
        }

        if (videoId || videoAccount || playerStyle) {
            elementId = "video-modal";
            vars.videoId = videoId;
            vars.videoAccount = videoAccount;
            vars.videoPlayerStyle = playerStyle;
        }

        $modalVideo.attr('data-video-id', vars.videoId);
        $modalVideo.attr('data-account', vars.videoAccount);
        $modalVideo.attr('data-player', vars.videoPlayerStyle);

        videojs(elementId).ready(function(){
            els.videoJS = this;
            els.videoJS.pause();

            els.curVideo = $(this);

            // Reload original video so we can show the poster image
            // Get the video object via the catalog
            els.videoJS.catalog.getVideo(vars.videoId, function(error, video) {
                // Load the video object into the player
                els.videoJS.catalog.load(video);
            });
        });

        changeVideo(vars.videoPlayerStyle);
        trapFocus();
    };

    var closeModal = function() {
        if (vars.isOpen) {
            els.modalComponent.addClass( 'close' );
            els.body.removeClass('modal-open');
            els.modalComponent.removeClass( 'open' );
            els.videoModal.pause();

            // Bring focus back to play button for accessibility
            els.curPlayButton.focus();
        }
    };

    // +++ Get, load and play video +++
    var changeVideo = function(dataPlayer) {
        bc('video-modal');
        videojs('video-modal').ready(function(){
            els.videoModal = this;

            // Get the video object via the catalog
            els.videoModal.catalog.getVideo(vars.videoId, function(error, video) {
                // Load the video object into the player
                els.videoModal.catalog.load(video);
                els.videoModal.play();
            });
        });
    };

    var init = function(el) {
        var $el = $(el);

        els = {
            component: $el,
            videoComponent: $('.video-modal'),
            modalComponent: $('.modal-video'),
            body: $('body'),
            closeModal: $('.modal-video').find('.close-modal'),
            modal: $el.find('.modal')
        };

        vars = {
            isOpen: false
        };

        listen();
    };

    return {
        init: init,
        showModal: function(videoId, videoAccount, playerStyle, modalLink) {
            init();
            openModal(null, videoId, videoAccount, playerStyle, modalLink);
        }
    };
};

$(function() {
    var $components = $('.video-modal');

    if ( $components.length > 0 ) {
        $components.each(function(i,el) {
            var modal = new DS.modalVideo();
            modal.init(el);
        });
    }
});

$(document).ready(function () {
  $('.launch-video-modal').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);
    var modalLink = e.target;
    var brightcoveAccount = $this.attr('data-brightcove-account');
    var brightcoveId = $this.attr('data-brightcove-id');
    var brightcovePlayerStyle = $this.attr('data-brightcove-player-style');
    DS.modalVideo().showModal(brightcoveId, brightcoveAccount, brightcovePlayerStyle, modalLink);
  });
});
