var DS = window.DS || {};
DS.discoveriesMap = function() {
  var els = {};
  var vars = {};
  var data = window.MARRIOTT_DISCOVERIES_MAP_PLACES;

  var listen = function() {
    $(window).on('googleMapsReady', function () {
      if ( els.map.length > 0 ) {
        initMap();
      }
    });
    els.listItems.on({
      'mouseenter': onListItemHover,
      'click': onListItemClick
    });
    els.back.on('click', onBackClick);
  };

  var onBackClick = function(e) {
    e.preventDefault();
    els.detailViewsContainer.removeClass('slid-in');
    els.listItemLinks.attr('tabindex',0);
    setTimeout(function() {
      els.detailViews.removeClass('shown');
    }, 300);
    els.listItemLinks.eq(vars.discoveryIndex).focus();
  };

  var onListItemHover = function(e) {
    resetListItemHighlighting();
    resetMarkerIcons();
    highlightMarkerIcon($(e.currentTarget).index());
  }

  var onListItemClick = function(e) {
    e.preventDefault();
    vars.discoveryIndex = $(e.currentTarget).index();
    onListItemHover(e);
    centerMapOn(parseFloat(data[vars.discoveryIndex].latitude), parseFloat(data[vars.discoveryIndex].longitude));
    showDiscovery(vars.discoveryIndex);
    els.listItemLinks.attr('tabindex',-1);
    els.back.focus();
  };

  var centerMapOn = function(lat,lng) {
    vars.map.setCenter({lat: lat, lng: lng});
  };

  var showDiscovery = function(discoveryIndex){
    els.detailViews.eq(discoveryIndex).addClass('shown');
    els.detailViewsContainer.addClass('slid-in');
  }

  var resetMarkerIcons = function () {
    for (var marker in vars.markers) {
      vars.markers[marker].setIcon(vars.defaultPinImage);
    }
  };

  var highlightMarkerIcon = function (index) {
    vars.markers[index].setIcon(vars.activePinImage);
  };

  var resetListItemHighlighting = function() {
    els.listItems.removeClass('active');
  };

  var highlightListItem = function(index) {
    els.listItems.eq(index).addClass('active');
  }

  var initMap =  function () {
    vars.mapInitialized = true;
    vars.map = new google.maps.Map(els.map[0], {
      center: {lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude)},
      zoom: 9,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scaleControl: false
    });
    setMarkers(vars.map);
    google.maps.event.addListenerOnce(vars.map, 'idle', function(){
       els.mapView.removeClass('map-not-loaded').addClass('map-loaded');
    });

  };

  var setMarkers = function(map) {
    vars.defaultPinImage = {
      url: vars.defaultIcon,
      size: new google.maps.Size(25, 39),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(12, 39)
    };

    vars.activePinImage = {
      url: vars.activeIcon,
      size: new google.maps.Size(36, 55),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(18, 55)
    };

    data.forEach(function(place,i) {
      if (place.name_copy && place.latitude && place.longitude) {
        var marker = new google.maps.Marker({
          position: {lat: parseFloat(place.latitude), lng: parseFloat(place.longitude)},
          map: map,
          icon: vars.defaultPinImage,
          title: place.name_copy,
          zIndex: i
        });
        vars.markers.push(marker);
        google.maps.event.addListener(marker, 'click', function(e) {
          els.detailViews.removeClass('shown');
          centerMapOn(marker.position);
          showDiscovery(i);
        });

        google.maps.event.addListener(marker, 'mouseover', function(e) {
          resetMarkerIcons();
          resetListItemHighlighting();
          highlightMarkerIcon(i);
          highlightListItem(i);
        });
      }
    });
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        contentContainer: $el.find('.map-content'),
        map: $el.find('.map'),
        mapView: $el.find('.map-view'),
        listItems: $el.find('.list-component li'),
        listItemLinks: $el.find('.list-component li a'),
        detailViewsContainer: $el.find('.detail-views-container'),
        detailViews: $el.find('.detail-view'),
        back: $el.find('.detail-view .back')
      };
      vars = {
        mapInitialized: false,
        markers: [],
        defaultIcon: $el.attr('data-map-pin-img-url'),
        activeIcon: $el.attr('data-map-current-pin-img-url'),
        discoveryIndex: 0
      };
      listen();
      if ( els.map.length > 0 && window.app.mapReady()  && !vars.mapInitialized ) {
        initMap();
      }
    }
  };
};

$(function() {
  if ( $('.discoveries-map-component').length > 0 ) {
    $('.discoveries-map-component').each(function(i,el) {
      var discoveriesMap = new DS.discoveriesMap();
      discoveriesMap.init(el);
    });
  }
});
