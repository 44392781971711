var DS = window.DS || {};
DS.deepLink = function() {
  var els = {};

  var checkURL = function() {
    var urlHashString = window.location.hash.replace('#','');
    var componentHashString = els.component.attr('data-deep-link-string');
    if(urlHashString === componentHashString) {
      $('html, body').animate({
        scrollTop: els.component.offset().top
      }, 200);
    }
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el
      };
      checkURL();
    }
  };
};

$(function() {
  $('[data-deep-link-string]').each(function(i,el) {
    var deepLink = new DS.deepLink();
    deepLink.init(el);
  });
});
