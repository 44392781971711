var DS = window.DS || {};
DS.video = function() {
    var els = {};

    var init = function(el) {
        var $el = $(el);
        els = {
            component: $el,
            playButton: $el.find('.vjs-big-play-button'),
            video: $el.find('.video-js'),
            logo: $el.find('.logo'),
            copy: $el.find('.copy-container')
        };

        if ( !window.app.isMobile() && (els.copy.length || els.logo.length) ) {
            $('<div/>', {class: 'info-container'}).appendTo(els.video).append(els.logo, els.copy, els.playButton);
        }
    };

    return {
        init: init
    };
};

$(function() {
    $('.video-component').each(function(i,el) {
        var video = new DS.video();
        video.init(el);
    });
});
