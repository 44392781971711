var DS = window.DS || {};

DS.escapeCarousel = function() {
  var els = {};
  var vars = {};

  var initCarousels = function() {
    els.imageCarousels.each(function() {
      var $carousel = $(this);
      if($carousel.find('.slide').length > 1) {
        $carousel.find('img').on('load', function(){
          $carousel.slick('resize');
        });
        $carousel.slick({
            arrows:true,
            infinite:false,
            nextArrow: '<button class="carousel-arrow carousel-arrow-next icon-carret" aria-label="'+vars.nextLabel+'"></button>',
            prevArrow: '<button class="carousel-arrow carousel-arrow-prev icon-carret" aria-label="'+vars.previousLabel+'"></button>',
            dots: true,
            slidesToShow:1,
            slidesToScroll:1,
            waitForAnimate:true,
            slide: '.slide',
            accessibility: false,
        });
      }
    });
    els.thumbnailCarousel.find('img').on('load', function(){
      $carousel.slick('resize');
    });
    els.thumbnailCarousel.slick({
        arrows:true,
        infinite:true,
        nextArrow: '<button class="carousel-arrow carousel-arrow-next icon-carret" aria-label="'+vars.nextLabel+'"></button>',
        prevArrow: '<button class="carousel-arrow carousel-arrow-prev icon-carret" aria-label="'+vars.previousLabel+'"></button>',
        dots: false,
        slidesToScroll:1,
        waitForAnimate:true,
        slide: '.slide',
        accessibility: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows:false,
            }
          }
        ],
    });
  };

  var listen = function() {
    els.dropdownTrigger.on('click', onDropdownTriggerClick);
    els.escapeFilters.on('click', onEscapeFilterClick);
    els.activityFilters.on('click', onActivityFilterClick);
    els.thumbnailCarousel.on('click', '.escape', onEscapeThumbnailClick);
  };

  var onDropdownTriggerClick = function() {
    els.dropdown.toggleClass('shown');
    els.filterHeadingBar.toggleClass('dropdown-shown');
  };

  var closeDropdown = function() {
    els.dropdown.removeClass('shown');
    els.filterHeadingBar.removeClass('dropdown-shown');
  };

  var clearActivityFilters = function() {
    var $allFilter = els.activityFilters.first();
    els.activityFilters.not($allFilter).removeClass('selected');
    $allFilter.addClass('selected');
  };

  var scrollIfNeeded = function() {
    var topOffset = els.filterHeadingBar.offset().top;
    var scroll = $(window).scrollTop();
    if(scroll - topOffset > 0) {
      $('html,body').animate({
        scrollTop: topOffset
      }, 200);
    }
  };


  var onEscapeFilterClick = function() {
    var $escapeFilter = $(this);
    var escapeId = $escapeFilter.attr('data-escape-id');
    var $carousel = els.imageCarousels.filter('[data-escape-id="' + escapeId + '"]');
    var $infoBlock = els.infoBlocks.filter('[data-escape-id="' + escapeId + '"]');
    els.thumbnailCarousel.slick('slickUnfilter');
    els.escapeFilters.not($escapeFilter).removeClass('selected');
    $escapeFilter.addClass('selected');
    clearActivityFilters();
    closeDropdown();
    els.imageCarousels.not($carousel).removeClass('shown');
    $carousel.addClass('shown');
    els.infoBlocks.not($infoBlock).removeClass('shown');
    $infoBlock.addClass('shown');
    els.thumbnailCarousel.slick('slickGoTo',$escapeFilter.index());
    if($carousel.find('.slide').length > 1) {
      $carousel.slick('getSlick').refresh();
    }
  };

  var onActivityFilterClick = function() {
    var $activityFilter = $(this);
    var activityId = $activityFilter.attr('data-activity-id');
    var $carousel;
    var $infoBlock;
    els.thumbnailCarousel.slick('slickUnfilter');
    els.activityFilters.not($activityFilter).removeClass('selected');
    $activityFilter.addClass('selected');
    closeDropdown();
    els.escapeFilters.removeClass('selected');
    if(activityId === 'all') {
      $carousel = els.imageCarousels.first();
      $infoBlock = els.infoBlocks.first();
    } else {
      $carousel = els.imageCarousels.filter('[data-activity-id="' + activityId + '"]');
      $infoBlock = els.infoBlocks.filter('[data-activity-id="' + activityId + '"]');
      els.thumbnailCarousel.slick('slickFilter', '.has-activity-' + activityId);
    }
    els.imageCarousels.not($carousel).removeClass('shown');
    $carousel.addClass('shown');
    if($carousel.find('.slide').length > 1) {
      $carousel.slick('getSlick').refresh();
    }
    els.infoBlocks.not($infoBlock).removeClass('shown');
    $infoBlock.addClass('shown');
    els.thumbnailCarousel.slick('getSlick').refresh();
  };

  var onEscapeThumbnailClick = function() {
    var $escapeThumbnail = $(this);
    var escapeId = $escapeThumbnail.attr('data-escape-id');
    var $carousel = els.imageCarousels.filter('[data-escape-id="' + escapeId + '"]');
    var $infoBlock = els.infoBlocks.filter('[data-escape-id="' + escapeId + '"]');
    els.thumbnailCarousel.slick('slickGoTo',$escapeThumbnail.attr('data-slick-index'));
    els.escapeFilters.removeClass('selected');
    closeDropdown();
    els.imageCarousels.not($carousel).removeClass('shown');
    $carousel.addClass('shown');
    els.infoBlocks.not($infoBlock).removeClass('shown');
    $infoBlock.addClass('shown');
    if($carousel.find('.slide').length > 1) {
      $carousel.slick('getSlick').refresh();
    }
    setTimeout(function() {
      scrollIfNeeded();
    },300);
  };

  return {
    init: function(el) {
      var $el = $(el);
      els = {
        component: $el,
        imageCarousels: $el.find('.image-carousel'),
        thumbnailCarousel: $el.find('.thumbnail-carousel'),
        escapeThumbnails: $el.find('.thumbnail-carousel .escape'),
        dropdownTrigger: $el.find('.dropdown-trigger'),
        dropdown: $el.find('.escape-filter-dropdown'),
        escapeFilters: $el.find('.escape-filter'),
        activityFilters: $el.find('.activity-filter'),
        infoBlocks: $el.find('.info-block'),
        filterHeadingBar: $el.find('.filter-heading-bar'),
      };

      vars = {
        nextLabel: $el.attr('data-next-label'),
        previousLabel: $el.attr('data-previous-label'),
      }
      initCarousels();
      listen();
    }
  };
};

$(function() {
  if ( $('.escape-carousel-component').length > 0 ) {
    $('.escape-carousel-component').each(function(i,el) {
      var escapeCarousel = new DS.escapeCarousel();
      escapeCarousel.init(el);
    });
  }
});
